import { createBrowserRouter } from 'react-router-dom';

// project import
import { SimpleLayoutType } from 'types/config';
import SimpleLayout from 'layout/Simple';
import Loadable from 'components/Loadable';
import { lazy } from 'react';
import ErrorBoundary from './ErrorBoundary';
import { PageUrl } from 'const/page-url';
const PagesLanding = Loadable(lazy(() => import('../pages/landing')));
const ProductAndSolution = Loadable(lazy(() => import('../pages/productAndSolution')));
const PrivacyPolicy = Loadable(lazy(() => import('../pages/privacyPolicy')));

// ==============================|| ROUTING RENDER ||============================== //

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <SimpleLayout layout={SimpleLayoutType.LANDING} />,
      errorElement: <ErrorBoundary />,
      children: [
        {
          index: true,
          element: <PagesLanding />
        },
        {
          path: PageUrl.ProductAndSolution.url,
          element: <ProductAndSolution />,
        },
        {
          path: PageUrl.PrivacyPolicy.url,
          element: <PrivacyPolicy />,
        },
      ]
    },
  ],
  { basename: process.env.REACT_APP_BASE_NAME }
);

export default router;
